import {
  defer,
  type LinksFunction,
  type MetaFunction,
  type LoaderArgs,
  type AppLoadContext,
} from '@shopify/remix-oxygen';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useLoaderData,
  useMatches,
} from '@remix-run/react';
import {
  ShopifySalesChannel,
  Seo,
  type SeoHandleFunction,
} from '@shopify/hydrogen';
import {Layout} from '~/components';
import {GenericError} from './components/GenericError';
import {NotFound} from './components/NotFound';

import styles from './styles/app.css';
// Import Swiper styles
import swiperStyles from 'swiper/swiper.min.css';
import swiperPaginationStyles from 'swiper/css/pagination';
import favicon from '../public/favicon.svg';

import {DEFAULT_LOCALE, parseMenu, type EnhancedMenu} from './lib/utils';
import invariant from 'tiny-invariant';
import {Shop, Cart} from '@shopify/hydrogen/storefront-api-types';
import {useAnalytics} from './hooks/useAnalytics';
import type {StorefrontContext} from './lib/type';
import {PRODUCT_CARD_FRAGMENT} from './data/fragments';
import hexRgb from 'hex-rgb';
import { domainToHandle } from './lib/const';

const seo: SeoHandleFunction<typeof loader> = ({data, pathname}) => {
  const onepageEntries =
    data?.onepage?.metaobject?.fields?.map(
      (field: {key: string; value?: string}) => {
        return [field.key, field.value];
      },
    ) ?? [];

  const onepageObject = {
    ...Object.fromEntries(onepageEntries),
    ...data?.onepage?.metaobject,
  };
  return {
    title: onepageObject.seo_title ?? '',
    titleTemplate: '%s',
    description: onepageObject.seo_description ?? '',
  };
};

export const handle = {
  seo,
};

export const links: LinksFunction = () => {
  return [
    {rel: 'stylesheet', href: styles},
    {rel: 'stylesheet', href: swiperStyles},
    {rel: 'stylesheet', href: swiperPaginationStyles},
    {
      rel: 'preconnect',
      href: 'https://cdn.shopify.com',
    },
    {
      rel: 'preconnect',
      href: 'https://shop.app',
    },
    {rel: 'icon', type: 'image/svg+xml', href: favicon},
  ];
};

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  viewport: 'width=device-width,initial-scale=1',
});

export async function loader({context, request}: LoaderArgs) {
  const url = new URL(request.url);
  const previewHandle = url.searchParams.get('preview');
  const [cartId, layout, onepage] = await Promise.all([
    context.session.get('cartId'),
    getLayoutData(context),
    context.storefront.query<any>(ONEPAGE_BASE_QUERY, {
      variables: {
        country: 'DE',
        language: 'DE',
        handle: previewHandle ?? domainToHandle(url.host) ?? 'scorpions',
      },
    }),
  ]);

  const pwCorrect = url.searchParams.get('pw') === 'adbanndpa';

  return defer({
    onepage,
    layout,
    pwCorrect,
    selectedLocale: context.storefront.i18n,
    cart: cartId ? getCart(context, cartId) : undefined,
    analytics: {
      shopifySalesChannel: ShopifySalesChannel.hydrogen,
      shopId: layout.shop.id,
    },
  });
}

export default function App() {
  const data = useLoaderData<typeof loader>();
  const locale = data.selectedLocale ?? DEFAULT_LOCALE;
  const hasUserConsent = true;

  useAnalytics(hasUserConsent, locale);

  const onepageEntries =
    data?.onepage?.metaobject?.fields?.map(
      (field: {key: string; value?: string}) => {
        return [field.key, field.value];
      },
    ) ?? [];

  const onepageObject = {
    ...Object.fromEntries(onepageEntries),
    ...data?.onepage?.metaobject,
  };
  const {
    accent_color_1: accentColor1,
    accent_color_2: accentColor2,
    text_color: textColor,
    background_color: backgroundColor,
    contrast_color: contrastColor,
  } = onepageObject;

  const colorToRgb = (color: string) => {
    const rgb = hexRgb(color);
    return `${rgb.red} ${rgb.green} ${rgb.blue}`;
  };

  return (
    <html lang={locale.language}>
      <head>
        <Seo />
        <Meta />
        <Links />
        <style
          dangerouslySetInnerHTML={{
            __html: `:root { 
              --color-primary: ${colorToRgb(accentColor1)}; 
              --color-secondary: ${colorToRgb(accentColor2)}; 
              --color-contrast: ${colorToRgb(contrastColor)}; 
              --color-main: ${colorToRgb(textColor)};
              --color-background: ${colorToRgb(backgroundColor)};
              --swiper-theme-color: rgb(${colorToRgb(accentColor1)});
            }`,
          }}
        ></style>
        <style
          dangerouslySetInnerHTML={{
            __html: `${onepageObject.custom_css}`,
          }}
        ></style>
      </head>
      <body class="max-w-[100vw] overflow-hidden">
        <Layout
          layout={data.layout as LayoutData}
          onepage={onepageObject}
          pwCorrect={data.pwCorrect}
          key={`${locale.language}-${locale.country}`}
        >
          <Outlet />
        </Layout>
        <div style={{display: 'none'}}>{JSON.stringify(onepageObject)}</div>
        <ScrollRestoration />
        <Scripts />
        <div
          data-freshworks
          dangerouslySetInnerHTML={{
            __html: ` <script>   /* This is the function to open the widget*/
    function openWidget() { FreshworksWidget('open'); }

    /* This is the function to open a specific solution article*/
    function openWidgetArticleImprint() { FreshworksWidget('open', 'article', { id: 22000278680 }); }
    function openWidgetArticleToc() { FreshworksWidget('open', 'article', { id: 22000278720 }); }
    function openWidgetArticlePrivacy() { FreshworksWidget('open', 'article', { id: 22000278719 }); }
    function openWidgetArticleShipping() { FreshworksWidget('open', 'article', { id: 22000278712 }); }
    function openWidgetArticleReturn() { FreshworksWidget('open', 'article', { id: 22000278683 }); }

    window.fwSettings = {
        'widget_id': 22000000486,
        'locale': 'de'
    };
    !function () { if ("function" != typeof window.FreshworksWidget) { var n = function () { n.q.push(arguments) }; n.q = [], window.FreshworksWidget = n } }() 
    
    
    </script>
    <script defer>
    (() => {
      const handler = (evt, func) => {
        evt.preventDefault();
        func();
      };
      document.querySelector('[href*="#contact"]').addEventListener('click', (evt) => { handler(evt, openWidget) });
      document.querySelector('[href*="#imprint"]').addEventListener('click', (evt) => { handler(evt, openWidgetArticleImprint) });
      document.querySelector('[href*="#toc"]').addEventListener('click', (evt) => { handler(evt, openWidgetArticleToc) });
      document.querySelector('[href*="#privacy"]').addEventListener('click', (evt) => { handler(evt, openWidgetArticlePrivacy) });
      document.querySelector('[href*="#shipping"]').addEventListener('click', (evt) => { handler(evt, openWidgetArticleShipping) });
      document.querySelector('[href*="#return"]').addEventListener('click', (evt) => { handler(evt, openWidgetArticleReturn) });
    })();
  </script>
    <script
      type="text/javascript"
      src="https://widget.freshworks.com/widgets/22000000486.js"
      async
      defer
    ></script>   `,
          }}
        ></div>
      </body>
    </html>
  );
}

export function CatchBoundary() {
  const [root] = useMatches();
  const caught = useCatch();
  const isNotFound = caught.status === 404;
  const locale = root.data?.selectedLocale ?? DEFAULT_LOCALE;

  return (
    <html lang={locale.language}>
      <head>
        <title>{isNotFound ? 'Not found' : 'Error'}</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Layout
          layout={root?.data?.layout}
          key={`${locale.language}-${locale.country}`}
        >
          {isNotFound ? (
            <NotFound type={caught.data?.pageType} />
          ) : (
            <GenericError
              error={{message: `${caught.status} ${caught.data}`}}
            />
          )}
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary({error}: {error: Error}) {
  const [root] = useMatches();
  const locale = root?.data?.selectedLocale ?? DEFAULT_LOCALE;

  return (
    <html lang={locale.language}>
      <head>
        <title>Error</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Layout layout={root?.data?.layout}>
          <GenericError error={error} />
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}

const LAYOUT_QUERY = `#graphql
  query layoutMenus(
    $language: LanguageCode
    $headerMenuHandle: String!
    $footerMenuHandle: String!
  ) @inContext(language: $language) {
    shop {
      id
      name
      description
    }
    headerMenu: menu(handle: $headerMenuHandle) {
      id
      items {
        ...MenuItem
        items {
          ...MenuItem
        }
      }
    }
    footerMenu: menu(handle: $footerMenuHandle) {
      id
      items {
        ...MenuItem
        items {
          ...MenuItem
        }
      }
    }
  }
  fragment MenuItem on MenuItem {
    id
    resourceId
    tags
    title
    type
    url
  }
`;

export interface LayoutData {
  headerMenu: EnhancedMenu;
  footerMenu: EnhancedMenu;
  shop: Shop;
  cart?: Promise<Cart>;
}

async function getLayoutData({storefront}: AppLoadContext) {
  const HEADER_MENU_HANDLE = 'main-menu';
  const FOOTER_MENU_HANDLE = 'footer';

  const data = await storefront.query<LayoutData>(LAYOUT_QUERY, {
    variables: {
      headerMenuHandle: HEADER_MENU_HANDLE,
      footerMenuHandle: FOOTER_MENU_HANDLE,
      language: storefront.i18n.language,
    },
  });

  invariant(data, 'No data returned from Shopify API');

  /*
    Modify specific links/routes (optional)
    @see: https://shopify.dev/api/storefront/unstable/enums/MenuItemType
    e.g here we map:
      - /blogs/news -> /news
      - /blog/news/blog-post -> /news/blog-post
      - /collections/all -> /products
  */
  const customPrefixes = {BLOG: '', CATALOG: 'products'};

  const headerMenu = data?.headerMenu
    ? parseMenu(data.headerMenu, customPrefixes)
    : undefined;

  const footerMenu = data?.footerMenu
    ? parseMenu(data.footerMenu, customPrefixes)
    : undefined;

  return {shop: data.shop, headerMenu, footerMenu};
}

const CART_QUERY = `#graphql
  query CartQuery($cartId: ID!, $country: CountryCode, $language: LanguageCode)
    @inContext(country: $country, language: $language) {
    cart(id: $cartId) {
      ...CartFragment
    }
  }

  fragment CartFragment on Cart {
    id
    checkoutUrl
    totalQuantity
    buyerIdentity {
      countryCode
      customer {
        id
        email
        firstName
        lastName
        displayName
      }
      email
      phone
    }
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            amountPerQuantity {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              availableForSale
              compareAtPrice {
                ...MoneyFragment
              }
              price {
                ...MoneyFragment
              }
              requiresShipping
              title
              image {
                ...ImageFragment
              }
              product {
                handle
                title
                id
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
    cost {
      subtotalAmount {
        ...MoneyFragment
      }
      totalAmount {
        ...MoneyFragment
      }
      totalDutyAmount {
        ...MoneyFragment
      }
      totalTaxAmount {
        ...MoneyFragment
      }
    }
    note
    attributes {
      key
      value
    }
    discountCodes {
      code
    }
  }

  fragment MoneyFragment on MoneyV2 {
    currencyCode
    amount
  }

  fragment ImageFragment on Image {
    id
    url
    altText
    width
    height
  }
`;

export async function getCart({storefront}: StorefrontContext, cartId: string) {
  invariant(storefront, 'missing storefront client in cart query');

  const {cart} = await storefront.query<{cart?: Cart}>(CART_QUERY, {
    variables: {
      cartId,
      country: storefront.i18n.country,
      language: storefront.i18n.language,
    },
    cache: storefront.CacheNone(),
  });

  return cart;
}

const ONEPAGE_BASE_QUERY = `#graphql
  ${PRODUCT_CARD_FRAGMENT}
  query onepageBase($country: CountryCode, $language: LanguageCode, $handle: String!)
  @inContext(country: $country, language: $language) {
    metaobject(handle: { handle: $handle, type: "one_page_store"}) {
      menus: field(key: "footer_menus") {
        references(first: 20) {
          nodes {
            ... on Metaobject {
              id
              title: field(key: "title") {
                value
              }
              links: field(key: "links") {
                references(first: 20) {
                  nodes {
                    ... on Metaobject {
                      id
                      label: field(key: "label") {
                        value
                      }
                      url: field(key: "url") {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      products: field(key: "products") {
        references(first: 20) {
          nodes {
            ...ProductCard
          }
        }
      }
      logo: field(key: "logo") {
        reference {
          ... on MediaImage {
            id
            image {
              altText
              url
            }
          }
        }
      }
      footerImage: field(key: "footer_image") {
        reference {
          ... on MediaImage {
            id
            image {
              altText
              url
            }
          }
        }
      }
      hero: field(key: "hero") {
        reference {
            ... on Metaobject {
              id
              title: field(key: "title") {
                value
              }
              text: field(key: "text") {
                value
              }
              buttonText: field(key: "button_text") {
                value
              }
              image: field(key: "image") {
                reference {
                  ... on MediaImage {
                    id
                    image {
                      altText
                      url
                    }
                  }
                }
              }
            }
          }
        }
      fields {
        key
        value
        type
      }
    }
  }
`;
